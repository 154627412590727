#wave-grey-top-three-layers {
  .path-0{
    animation:pathAnim-0 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-0{
    0%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 93.40669856459331,174.40669856459328 186.81339712918663,173.8133971291866 282,165 C 377.1866028708134,156.1866028708134 474.1531100478468,139.1531100478469 558,136 C 641.8468899521532,132.8468899521531 712.5741626794259,143.57416267942588 816,157 C 919.4258373205741,170.42583732057412 1055.5502392344497,186.55023923444975 1165,190 C 1274.4497607655503,193.44976076555025 1357.2248803827752,184.22488038277513 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 110.22009569377991,183.6602870813397 220.44019138755982,192.3205741626794 325,175 C 429.5598086124402,157.6794258373206 528.4593301435407,114.37799043062205 624,125 C 719.5406698564593,135.62200956937795 811.7224880382776,200.16746411483248 899,216 C 986.2775119617224,231.83253588516752 1068.6507177033493,198.95215311004787 1158,184 C 1247.3492822966507,169.04784688995213 1343.6746411483255,172.02392344497605 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 111.16746411483254,201.89952153110048 222.33492822966508,228.79904306220095 330,219 C 437.6650717703349,209.20095693779905 541.8277511961721,162.70334928229664 620,144 C 698.1722488038279,125.29665071770334 750.354066985646,134.38755980861245 834,131 C 917.645933014354,127.61244019138755 1032.7559808612439,111.7464114832536 1139,117 C 1245.2440191387561,122.2535885167464 1342.622009569378,148.6267942583732 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 113.01435406698565,212.1961722488038 226.0287081339713,249.39234449760764 309,239 C 391.9712918660287,228.60765550239236 444.8995215311004,170.62679425837322 535,157 C 625.1004784688996,143.37320574162678 752.3732057416269,174.10047846889952 869,178 C 985.6267942583731,181.89952153110048 1091.6076555023922,158.9712918660287 1185,154 C 1278.3923444976078,149.0287081339713 1359.196172248804,162.01435406698565 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 93.40669856459331,174.40669856459328 186.81339712918663,173.8133971291866 282,165 C 377.1866028708134,156.1866028708134 474.1531100478468,139.1531100478469 558,136 C 641.8468899521532,132.8468899521531 712.5741626794259,143.57416267942588 816,157 C 919.4258373205741,170.42583732057412 1055.5502392344497,186.55023923444975 1165,190 C 1274.4497607655503,193.44976076555025 1357.2248803827752,184.22488038277513 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
  }
  .path-1{
    animation:pathAnim-1 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-1{
    0%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 70.86124401913875,365.4736842105263 141.7224880382775,380.9473684210526 250,368 C 358.2775119617225,355.0526315789474 503.97129186602865,313.6842105263158 593,306 C 682.0287081339713,298.3157894736842 714.3923444976077,324.31578947368416 815,343 C 915.6076555023923,361.68421052631584 1084.4593301435407,373.0526315789474 1200,373 C 1315.5406698564593,372.9473684210526 1377.7703349282297,361.4736842105263 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 119.65550239234452,366.78468899521533 239.31100478468903,383.5693779904306 333,400 C 426.68899521531097,416.4306220095694 494.4114832535885,432.5071770334928 583,410 C 671.5885167464115,387.4928229665072 781.0430622009569,326.4019138755981 870,315 C 958.9569377990431,303.5980861244019 1027.4162679425838,341.88516746411483 1119,356 C 1210.5837320574162,370.11483253588517 1325.2918660287082,360.0574162679426 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 126.17224880382778,361.7129186602871 252.34449760765557,373.4258373205742 337,362 C 421.65550239234443,350.5741626794258 464.7942583732056,316.00956937799043 539,323 C 613.2057416267944,329.99043062200957 718.4784688995218,378.53588516746413 818,403 C 917.5215311004782,427.46411483253587 1011.291866028708,427.8468899521531 1114,415 C 1216.708133971292,402.1531100478469 1328.354066985646,376.07655502392345 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 107.2153110047847,377.33014354066984 214.4306220095694,404.66028708133973 304,413 C 393.5693779904306,421.33971291866027 465.4928229665072,410.6889952153109 545,414 C 624.5071770334928,417.3110047846891 711.5980861244019,434.58373205741634 826,415 C 940.4019138755981,395.41626794258366 1082.1148325358852,338.9760765550239 1189,322 C 1295.8851674641148,305.0239234449761 1367.9425837320573,327.5119617224881 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 70.86124401913875,365.4736842105263 141.7224880382775,380.9473684210526 250,368 C 358.2775119617225,355.0526315789474 503.97129186602865,313.6842105263158 593,306 C 682.0287081339713,298.3157894736842 714.3923444976077,324.31578947368416 815,343 C 915.6076555023923,361.68421052631584 1084.4593301435407,373.0526315789474 1200,373 C 1315.5406698564593,372.9473684210526 1377.7703349282297,361.4736842105263 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
  }
  .path-2{
    animation:pathAnim-2 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-2{
    0%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 123.07177033492826,504.57894736842104 246.14354066985652,484.15789473684214 339,475 C 431.8564593301435,465.84210526315786 494.4976076555023,467.9473684210526 591,467 C 687.5023923444977,466.0526315789474 817.8660287081342,462.0526315789474 916,468 C 1014.1339712918658,473.9473684210526 1080.0382775119617,489.8421052631578 1162,501 C 1243.9617224880383,512.1578947368422 1341.9808612440193,518.578947368421 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 96.09569377990434,510.7894736842105 192.19138755980867,496.5789473684211 289,503 C 385.8086124401913,509.4210526315789 483.33014354066984,536.4736842105262 589,556 C 694.6698564593302,575.5263157894738 808.488038277512,587.5263157894736 906,568 C 1003.511961722488,548.4736842105264 1084.7177033492824,497.42105263157896 1171,485 C 1257.2822966507176,472.57894736842104 1348.6411483253587,498.7894736842105 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 82.04784688995213,540.2918660287081 164.09569377990425,555.5837320574162 268,554 C 371.90430622009575,552.4162679425838 497.665071770335,533.9569377990431 605,534 C 712.334928229665,534.0430622009569 801.244019138756,552.5885167464115 876,563 C 950.755980861244,573.4114832535885 1011.3588516746413,575.688995215311 1103,568 C 1194.6411483253587,560.311004784689 1317.3205741626793,542.6555023923445 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 77.49282296650716,515.4401913875598 154.98564593301433,505.8803827751196 265,497 C 375.0143540669857,488.1196172248804 517.5502392344499,479.91866028708137 613,500 C 708.4497607655501,520.0813397129186 756.8133971291865,568.444976076555 834,583 C 911.1866028708135,597.555023923445 1017.1961722488038,578.3014354066986 1123,563 C 1228.8038277511962,547.6985645933014 1334.401913875598,536.3492822966507 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 123.07177033492826,504.57894736842104 246.14354066985652,484.15789473684214 339,475 C 431.8564593301435,465.84210526315786 494.4976076555023,467.9473684210526 591,467 C 687.5023923444977,466.0526315789474 817.8660287081342,462.0526315789474 916,468 C 1014.1339712918658,473.9473684210526 1080.0382775119617,489.8421052631578 1162,501 C 1243.9617224880383,512.1578947368422 1341.9808612440193,518.578947368421 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
  }
}
