#wave-grey-bottom-three-layers {
  .path-0{
    animation:pathAnim-0 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-0{
    0%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 108.5263157894737,181.83253588516746 217.0526315789474,188.66507177033492 316,190 C 414.9473684210526,191.33492822966508 504.31578947368416,187.17224880382773 598,172 C 691.6842105263158,156.82775119617227 789.684210526316,130.64593301435406 870,136 C 950.315789473684,141.35406698564594 1012.9473684210525,178.244019138756 1105,190 C 1197.0526315789475,201.755980861244 1318.5263157894738,188.377990430622 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 116.2870813397129,164.60765550239233 232.5741626794258,154.2153110047847 315,148 C 397.4258373205742,141.7846889952153 445.9904306220096,139.74641148325358 539,147 C 632.0095693779904,154.25358851674642 769.4641148325359,170.79904306220095 883,161 C 996.5358851674641,151.20095693779905 1086.153110047847,115.05741626794259 1175,113 C 1263.846889952153,110.94258373205741 1351.9234449760766,142.9712918660287 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 108.75598086124404,154.2535885167464 217.51196172248808,133.5071770334928 308,124 C 398.4880382775119,114.49282296650719 470.7081339712918,116.22488038277513 548,121 C 625.2918660287082,125.77511961722487 707.6555023923446,133.59330143540672 812,128 C 916.3444976076554,122.40669856459328 1042.66985645933,103.40191387559808 1151,109 C 1259.33014354067,114.59808612440192 1349.665071770335,144.79904306220095 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 93.23444976076553,149.79425837320574 186.46889952153106,124.58851674641147 287,128 C 387.53110047846894,131.41148325358853 495.3588516746412,163.4401913875598 583,181 C 670.6411483253588,198.5598086124402 738.0956937799043,201.65071770334927 842,187 C 945.9043062200957,172.34928229665073 1086.2583732057417,139.95693779904306 1192,135 C 1297.7416267942583,130.04306220095694 1368.870813397129,152.52153110047846 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 108.5263157894737,181.83253588516746 217.0526315789474,188.66507177033492 316,190 C 414.9473684210526,191.33492822966508 504.31578947368416,187.17224880382773 598,172 C 691.6842105263158,156.82775119617227 789.684210526316,130.64593301435406 870,136 C 950.315789473684,141.35406698564594 1012.9473684210525,178.244019138756 1105,190 C 1197.0526315789475,201.755980861244 1318.5263157894738,188.377990430622 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
  }
  .path-1{
    animation:pathAnim-1 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-1{
    0%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 62.17224880382773,326.8995215311005 124.34449760765546,303.79904306220095 238,301 C 351.65550239234454,298.20095693779905 516.7942583732058,315.70334928229664 608,310 C 699.2057416267942,304.29665071770336 716.4784688995214,275.3875598086125 813,284 C 909.5215311004786,292.6124401913875 1085.2918660287082,338.7464114832536 1203,356 C 1320.7081339712918,373.2535885167464 1380.3540669856459,361.6267942583732 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 93.53110047846891,351.6076555023924 187.06220095693783,353.2153110047847 285,352 C 382.93779904306217,350.7846889952153 485.2822966507176,346.74641148325355 584,344 C 682.7177033492824,341.25358851674645 777.8086124401914,339.79904306220095 884,355 C 990.1913875598086,370.20095693779905 1107.4832535885168,402.05741626794264 1202,404 C 1296.5167464114832,405.94258373205736 1368.2583732057415,377.97129186602865 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 125.12918660287082,344.6985645933014 250.25837320574163,339.39712918660285 328,329 C 405.74162679425837,318.60287081339715 436.0956937799042,303.1100478468899 531,302 C 625.9043062200958,300.8899521531101 785.3588516746411,314.1626794258373 908,332 C 1030.641148325359,349.8373205741627 1116.468899521531,372.2392344497607 1199,376 C 1281.531100478469,379.7607655502393 1360.7655502392345,364.88038277511964 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 67.29186602870809,335.0047846889952 134.58373205741617,320.00956937799043 239,321 C 343.41626794258383,321.99043062200957 484.9569377990432,338.9665071770335 590,361 C 695.0430622009568,383.0334928229665 763.5885167464114,410.1244019138756 840,402 C 916.4114832535886,393.8755980861244 1000.688995215311,350.53588516746413 1102,336 C 1203.311004784689,321.46411483253587 1321.6555023923445,335.73205741626793 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 62.17224880382773,326.8995215311005 124.34449760765546,303.79904306220095 238,301 C 351.65550239234454,298.20095693779905 516.7942583732058,315.70334928229664 608,310 C 699.2057416267942,304.29665071770336 716.4784688995214,275.3875598086125 813,284 C 909.5215311004786,292.6124401913875 1085.2918660287082,338.7464114832536 1203,356 C 1320.7081339712918,373.2535885167464 1380.3540669856459,361.6267942583732 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
  }
  .path-2{
    animation:pathAnim-2 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-2{
    0%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 108.09569377990428,497.14354066985646 216.19138755980856,469.2870813397129 305,468 C 393.80861244019144,466.7129186602871 463.33014354066995,491.9952153110048 565,517 C 666.66985645933,542.0047846889952 800.4880382775118,566.7320574162679 897,575 C 993.5119617224882,583.2679425837321 1052.7177033492824,575.0765550239234 1137,564 C 1221.2822966507176,552.9234449760766 1330.6411483253587,538.9617224880383 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 130.78468899521528,515.0574162679426 261.56937799043055,505.1148325358851 339,503 C 416.43062200956945,500.8851674641149 440.5071770334929,506.5980861244019 523,496 C 605.4928229665071,485.4019138755981 746.401913875598,458.4928229665073 858,475 C 969.598086124402,491.5071770334927 1051.8851674641148,551.4306220095693 1144,567 C 1236.1148325358852,582.5693779904307 1338.0574162679427,553.7846889952153 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 99.80861244019135,515.1818181818182 199.6172248803827,505.3636363636364 301,497 C 402.3827751196173,488.6363636363636 505.33971291866044,481.7272727272727 587,491 C 668.6602870813396,500.2727272727273 729.0239234449759,525.7272727272727 832,525 C 934.9760765550241,524.2727272727273 1080.5645933014355,497.3636363636364 1189,493 C 1297.4354066985645,488.6363636363636 1368.7177033492821,506.8181818181818 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 105.99043062200957,532.6650717703349 211.98086124401914,540.3301435406698 294,520 C 376.01913875598086,499.66985645933016 434.06698564593296,451.3444976076555 534,459 C 633.933014354067,466.6555023923445 775.7511961722489,530.2918660287081 878,533 C 980.2488038277511,535.7081339712919 1042.9282296650717,477.48803827751203 1130,466 C 1217.0717703349283,454.51196172248797 1328.5358851674641,489.755980861244 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 108.09569377990428,497.14354066985646 216.19138755980856,469.2870813397129 305,468 C 393.80861244019144,466.7129186602871 463.33014354066995,491.9952153110048 565,517 C 666.66985645933,542.0047846889952 800.4880382775118,566.7320574162679 897,575 C 993.5119617224882,583.2679425837321 1052.7177033492824,575.0765550239234 1137,564 C 1221.2822966507176,552.9234449760766 1330.6411483253587,538.9617224880383 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
  }

}
