@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './components/_navbar';
@import './components/_wave-grey-bottom-three-layers';
@import './components/_wave-orange-bottom-three-layers';
@import './components/_wave-grey-top-three-layers';
@import './components/_wave-orange-top-three-layers';

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
