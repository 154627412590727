#wave-orange-bottom-three-layers {

  .path-0{
  animation:pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  }
  @keyframes pathAnim-0{
    0%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 85.10047846889952,164.39712918660285 170.20095693779905,153.79425837320574 276,145 C 381.79904306220095,136.20574162679426 508.2966507177033,129.2200956937799 609,128 C 709.7033492822967,126.77990430622009 784.6124401913876,131.32535885167465 877,144 C 969.3875598086124,156.67464114832535 1079.2535885167463,177.47846889952154 1176,184 C 1272.7464114832537,190.52153110047846 1356.3732057416269,182.76076555023923 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 89.99043062200957,153.5645933014354 179.98086124401914,132.1291866028708 277,128 C 374.01913875598086,123.8708133971292 478.0669856459331,137.04784688995218 571,156 C 663.9330143540669,174.95215311004782 745.7511961722489,199.67942583732057 831,186 C 916.2488038277511,172.32057416267943 1004.9282296650717,120.23444976076554 1107,112 C 1209.0717703349283,103.76555023923446 1324.5358851674641,139.38277511961724 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 77.91387559808612,159.03827751196172 155.82775119617224,143.07655502392345 265,127 C 374.17224880382776,110.92344497607655 514.6028708133971,94.73205741626793 629,117 C 743.3971291866029,139.26794258373207 831.7607655502394,199.99521531100478 909,219 C 986.2392344497606,238.00478468899522 1052.3540669856459,215.28708133971293 1139,201 C 1225.6459330143541,186.71291866028707 1332.8229665071772,180.85645933014354 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 83.73205741626796,146.80861244019138 167.46411483253593,118.61722488038276 274,128 C 380.5358851674641,137.38277511961724 509.87559808612434,184.3397129186603 596,183 C 682.1244019138757,181.6602870813397 725.0334928229667,132.0239234449761 817,113 C 908.9665071770333,93.97607655502391 1049.9904306220094,105.5645933014354 1162,121 C 1274.0095693779906,136.4354066985646 1357.0047846889952,155.71770334928232 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,175 0,175 C 85.10047846889952,164.39712918660285 170.20095693779905,153.79425837320574 276,145 C 381.79904306220095,136.20574162679426 508.2966507177033,129.2200956937799 609,128 C 709.7033492822967,126.77990430622009 784.6124401913876,131.32535885167465 877,144 C 969.3875598086124,156.67464114832535 1079.2535885167463,177.47846889952154 1176,184 C 1272.7464114832537,190.52153110047846 1356.3732057416269,182.76076555023923 1440,175 C 1440,175 1440,700 1440,700 Z");
    }
  }
  .path-1{
    animation:pathAnim-1 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-1{
    0%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 124.86124401913875,323.57894736842104 249.7224880382775,297.1578947368421 333,304 C 416.2775119617225,310.8421052631579 457.97129186602865,350.94736842105266 552,365 C 646.0287081339713,379.05263157894734 792.3923444976077,367.0526315789474 908,353 C 1023.6076555023923,338.9473684210526 1108.4593301435407,322.84210526315786 1192,322 C 1275.5406698564593,321.15789473684214 1357.7703349282297,335.57894736842104 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 70.99521531100476,362.2200956937799 141.9904306220095,374.4401913875598 241,368 C 340.0095693779905,361.5598086124402 467.03349282296665,336.4593301435407 579,325 C 690.9665071770333,313.5406698564593 787.8755980861245,315.7224880382775 893,316 C 998.1244019138755,316.2775119617225 1111.4641148325359,314.6507177033493 1204,320 C 1296.5358851674641,325.3492822966507 1368.267942583732,337.67464114832535 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 105.5023923444976,359.6076555023924 211.0047846889952,369.2153110047847 306,356 C 400.9952153110048,342.7846889952153 485.48325358851685,306.74641148325355 581,308 C 676.5167464114832,309.25358851674645 783.0622009569377,347.7990430622009 873,366 C 962.9377990430623,384.2009569377991 1036.267942583732,382.0574162679426 1128,376 C 1219.732057416268,369.9425837320574 1329.8660287081339,359.9712918660287 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 100.72727272727272,367.1483253588517 201.45454545454544,384.29665071770336 301,368 C 400.54545454545456,351.70334928229664 498.9090909090909,301.96172248803833 585,305 C 671.0909090909091,308.03827751196167 744.909090909091,363.8564593301435 832,381 C 919.090909090909,398.1435406698565 1019.4545454545455,376.6124401913876 1123,365 C 1226.5454545454545,353.3875598086124 1333.2727272727273,351.6937799043062 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,350 0,350 C 124.86124401913875,323.57894736842104 249.7224880382775,297.1578947368421 333,304 C 416.2775119617225,310.8421052631579 457.97129186602865,350.94736842105266 552,365 C 646.0287081339713,379.05263157894734 792.3923444976077,367.0526315789474 908,353 C 1023.6076555023923,338.9473684210526 1108.4593301435407,322.84210526315786 1192,322 C 1275.5406698564593,321.15789473684214 1357.7703349282297,335.57894736842104 1440,350 C 1440,350 1440,700 1440,700 Z");
    }
  }
  .path-2{
    animation:pathAnim-2 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-2{
    0%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 92.60287081339715,561.7464114832536 185.2057416267943,598.4928229665072 272,580 C 358.7942583732057,561.5071770334928 439.77990430622003,487.77511961722496 545,473 C 650.22009569378,458.22488038277504 779.6746411483253,502.4066985645933 873,512 C 966.3253588516747,521.5933014354067 1023.5215311004786,496.5980861244019 1112,493 C 1200.4784688995214,489.4019138755981 1320.2392344497607,507.20095693779905 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    25%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 107.79904306220098,509.9856459330144 215.59808612440196,494.97129186602876 311,502 C 406.40191387559804,509.02870813397124 489.4066985645933,538.1004784688995 591,547 C 692.5933014354067,555.8995215311005 812.7751196172248,544.6267942583733 914,548 C 1015.2248803827752,551.3732057416267 1097.4928229665072,569.3923444976077 1182,568 C 1266.5071770334928,566.6076555023923 1353.2535885167463,545.8038277511962 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    50%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 91.78947368421055,528.4354066985645 183.5789473684211,531.8708133971292 284,549 C 384.4210526315789,566.1291866028708 493.47368421052624,596.9521531100479 598,577 C 702.5263157894738,557.0478468899521 802.5263157894736,486.32057416267946 882,473 C 961.4736842105264,459.67942583732054 1020.421052631579,503.76555023923447 1110,522 C 1199.578947368421,540.2344497607655 1319.7894736842104,532.6172248803828 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    75%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 117.79904306220098,488.99043062200957 235.59808612440196,452.98086124401914 338,460 C 440.40191387559804,467.01913875598086 527.4066985645933,517.0669856459331 621,545 C 714.5933014354067,572.9330143540669 814.7751196172247,578.7511961722488 908,565 C 1001.2248803827753,551.2488038277512 1087.4928229665072,517.9282296650717 1175,508 C 1262.5071770334928,498.07177033492826 1351.2535885167463,511.53588516746413 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
    100%{
      d: path("M 0,700 C 0,700 0,525 0,525 C 92.60287081339715,561.7464114832536 185.2057416267943,598.4928229665072 272,580 C 358.7942583732057,561.5071770334928 439.77990430622003,487.77511961722496 545,473 C 650.22009569378,458.22488038277504 779.6746411483253,502.4066985645933 873,512 C 966.3253588516747,521.5933014354067 1023.5215311004786,496.5980861244019 1112,493 C 1200.4784688995214,489.4019138755981 1320.2392344497607,507.20095693779905 1440,525 C 1440,525 1440,700 1440,700 Z");
    }
  }
}
